import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumb from 'Common/BreadCrumb';
import Flatpickr from 'react-flatpickr';
import TableContainer from 'Common/TableContainer';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AssignBikeModal from './AssignBikeModal';

import { getBikemanAttendanceByMonth, GetBikemanAttendanceByMonthResponse } from 'Common/data/attendance/GetIndividualBikemanAttendanceByMonth';
import { preprocessData, calculateStatus } from './attendanceUtils';
import { getBikemanById, GetBikemanByIdResponse } from 'Common/data/bike/GetBikemanById';

// Icons
import { Search, Check, X, Clock, XOctagon, RefreshCw } from 'lucide-react';
// Image
import user3 from "assets/images/users/user-3.jpg";

// react-redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getAttendance as onGetAttendance } from 'slices/thunk';

const AttendanceHR = () => {
    const { id } = useParams<{ id?: string }>();

    const [data, setData] = useState<any>([]);
    const [selectedDate, setSelectedDate] = useState<Date[]>([new Date()]);
    const [bikemanDetails, setBikemanDetails] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    // Inside your fetchData function:
    const fetchData = async (selectedYear: number, selectedMonth: string) => {
        try {
            // Ensure that id exists and has a value before proceeding
            if (id) {
                console.log('Bikeman ID:', id);
                console.log('Month name:', selectedMonth);
                console.log('Year:', selectedYear);
                console.log('Selected date:', selectedDate[0]);
            
            const response: GetBikemanAttendanceByMonthResponse = await getBikemanAttendanceByMonth(+id,    selectedYear, selectedMonth.toString());
                setData(response.data);
            
                // Log the response
                console.log('API response:', response);
            } else {
                console.log('No bikeman ID found in the URL');
            }
        } catch (error) {
            console.error('Error fetching attendance:', error);
            // Handle errors here
        }
    };


    const fetchBikemanDetails = async (bikemanId: number) => {
        try {
            const response: GetBikemanByIdResponse = await getBikemanById(bikemanId);
            setBikemanDetails(response.results);
            console.log('Bikeman details:', response.results);
        } catch (error) {
            console.error('Error fetching bikeman details:', error);
            // Handle errors here
        }
    };

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear - 10; i <= currentYear; i++) {
            years.push({ value: i, label: i.toString() });
        }
        return years;
    }, []);

    useEffect(() => {
        const selectedYear = selectedDate[0]?.getFullYear();
        const selectedMonth = monthNames[selectedDate[0]?.getMonth()];
        fetchData(selectedYear, selectedMonth.toString());
    }, [selectedDate]);

    useEffect(() => {
        if (id) {
            const bikemanId = parseInt(id, 10);
            if (!isNaN(bikemanId)) {
                fetchBikemanDetails(bikemanId);
            } else {
                console.error('Invalid bikeman ID:', id);
            }
        }
    }, [id]);

    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.HRManagment,
        (state) => ({
            dataList: state.attendancelist
        })
    );

    const { dataList } = useSelector(selectDataList);

    useEffect(() => {
        dispatch(onGetAttendance());
    }, [dispatch]);

    useEffect(() => {
        setData(dataList);
    }, [dataList]);

    const [formattedData, setFormattedData] = useState<any>([]);

    useEffect(() => {
        const formatted = preprocessData(data);
        setFormattedData(formatted);
    }, [data]);

    const columns = useMemo(() => [
        {
            header: "Date",
            accessorKey: "date",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <React.Fragment>
                    {cell.getValue()} <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-white border-slate-400 text-slate-500 dark:bg-zink-700 dark:border-zink-400 dark:text-zink-200 ltr:ml-1 rtl:mr-1 align-middle">{cell.row.original.day}</span>
                </React.Fragment>
            ),
        },
        {
            header: "Bike Assigned",
            accessorKey: "bike",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <React.Fragment>
                    {cell.row.original.bikeName} ({cell.row.original.plateNumber})
                </React.Fragment>
            ),
        },
        {
            header: "Check In",
            accessorKey: "checkIn",
            enableColumnFilter: false
        },
        {
            header: "Check Out",
            accessorKey: "checkOut",
            enableColumnFilter: false,
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            cell: (cell: any) => (
                calculateStatus(cell.row.original.checkIn, cell.row.original.checkOut)
            ),
        },
    ], []);

    const handleAssignBikeClick = () => {
        setIsModalOpen(true);
    };

    const handleAssignBike = (bikeId: number) => {
        console.log('Assigned bike ID:', bikeId);
    };

    const handleYearChange = (selectedOption: any) => {
        const newDate = new Date(selectedDate[0]);
        newDate.setFullYear(selectedOption.value);
        setSelectedDate([newDate]);
    };

    const handleMonthChange = (selectedOption: any) => {
        const newDate = new Date(selectedDate[0]);
        newDate.setMonth(monthNames.indexOf(selectedOption.value));
        setSelectedDate([newDate]);
    };

    const monthOptions = monthNames.map((month, index) => ({
        value: month,
        label: month,
    }));

    return (
        <React.Fragment>
            <BreadCrumb title='Attendance' pageTitle='Attendance' />
            <div className="grid grid-cols-1 lg:grid-cols-12 xl:grid-cols-12 gap-x-5">
                <div className="lg:col-span-12 xl:col-span-3 xl:row-span-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-center">
                                <h6 className="mt-3 mb-1 text-16"><Link to="#!">{bikemanDetails?.firstName} {bikemanDetails?.lastName}</Link></h6>
                                <p className="text-slate-500 dark:text-zink-200">Rider</p>
                            </div>
                            <div className="mt-5 overflow-x-auto">
                                <table className="w-full mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Joining Date</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{new Date(bikemanDetails?.createdAt).toLocaleDateString()}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Phone number</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{bikemanDetails?.phoneNumber || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">Email</td>
                                            <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">{bikemanDetails?.email}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-9 lg:col-span-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="grid grid-cols-1 gap-4 mb-5 lg:grid-cols-2 xl:grid-cols-12">
                                <div className="xl:col-span-3">
                                    <Select
                                        options={yearOptions}
                                        onChange={handleYearChange}
                                        defaultValue={yearOptions.find(option => option.value === selectedDate[0]?.getFullYear())}
                                    />
                                </div>
                                <div className="xl:col-span-3">
                                    <Select
                                        options={monthOptions}
                                        onChange={handleMonthChange}
                                        defaultValue={monthOptions.find(option => option.value === monthNames[selectedDate[0]?.getMonth()])}
                                    />
                                </div>
                                <div className="flex justify-end gap-2 text-right lg:col-span-2 xl:col-span-4 xl:col-start-10">
                                    <button onClick={handleAssignBikeClick} className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Assign Bike</button>
                                </div>
                            </div>
                            {data && data.length > 0 ?
                                <TableContainer
                                    isPagination={true}
                                    columns={(columns || [])}
                                    data={(formattedData || [])}
                                    customPageSize={10}
                                    divclassName="overflow-x-auto"
                                    tableclassName="w-full whitespace-nowrap"
                                    theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                                    tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                                    PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                                />
                                :
                                (<div className="noresult">
                                    <div className="py-6 text-center">
                                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                        <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched more than 10+ Attendance We did not find any Attendance for you search.</p>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AssignBikeModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} onAssignBike={handleAssignBike} />
        </React.Fragment>
    );    
};

export default AttendanceHR;
