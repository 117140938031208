import React, { useEffect, useState } from "react";
import { getAdminProfile } from 'Common/data/adminAuth/GetAdminProfile';
import { updateAdminProfile } from 'Common/data/adminAuth/UpdateAdminProfile';

const PersonalTabs = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    });

    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                const profileData = await getAdminProfile();
                const { results } = profileData;

                setFormData({
                    firstName: results.firstName,
                    lastName: results.lastName,
                    phoneNumber: results.phoneNumber,
                    email: results.email
                });
            } catch (error) {
                console.error('Error fetching profile data', error);
            }
        }

        fetchData();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');

        try {
            const updateData = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                phoneNumber: formData.phoneNumber,
                state: '',
                profile_image_url: new File([], "")
            };

            const response = await updateAdminProfile(updateData);
            console.log('Profile updated successfully', response);
            setSuccessMessage('Profile updated successfully!');
        } catch (error) {
            console.error('Error updating profile', error);
            setErrorMessage('Error updating profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-1 text-15">Personal Information</h6>
                    <p className="mb-4 text-slate-500 dark:text-zink-200">Update your photo and personal details here easily.</p>
                    {successMessage && (
                        <div className="px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                            <strong>Success!</strong> {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-400/20">
                            <strong>Error!</strong> {errorMessage}
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                            <div className="xl:col-span-6">
                                <label htmlFor="firstName" className="inline-block mb-2 text-base font-medium">First Name</label>
                                <input type="text" id="firstName" value={formData.firstName} onChange={handleInputChange} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter your value" />
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="lastName" className="inline-block mb-2 text-base font-medium">Last Name</label>
                                <input type="text" id="lastName" value={formData.lastName} onChange={handleInputChange} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter your value" />
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="phoneNumber" className="inline-block mb-2 text-base font-medium">Phone Number</label>
                                <input type="text" id="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="+214 8456 8459 23" />
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="email" className="inline-block mb-2 text-base font-medium">Email Address</label>
                                <input type="email" id="email" value={formData.email} disabled onChange={handleInputChange} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter your email address" />
                            </div>
                        </div>
                        <div className="mt-5">
                            <button type="submit" className={`text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 ${loading ? 'cursor-not-allowed opacity-50' : ''}`} disabled={loading}>
                                {loading ? 'Saving...' : 'Save Changes'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PersonalTabs;
