import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface GetBikemanAttendanceByMonthResponse {
    data(data: any): unknown;
    status_code: number;
    results: any;
}

async function getBikemanAttendanceByMonth(bikemanId: number, year: number, month: string): Promise<GetBikemanAttendanceByMonthResponse> {
    const apiUrl = `api/v1/attendances/attendance/bikeman/month?bikemanId=${bikemanId}&year=${year}&month=${month}`;

    try {
        const response: AxiosResponse<GetBikemanAttendanceByMonthResponse> = await axiosInstance.get<GetBikemanAttendanceByMonthResponse>(apiUrl);

        // Log the entire response data
        console.log(`Get Bikeman Attendance By Month (Bikeman ID: ${bikemanId}, Year: ${year}, Month: ${month}) Response:`, response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error(`Error fetching attendance for Bikeman ID (${bikemanId}), Year (${year}), and Month (${month}):`, error.message);
        // Handle errors here
        throw error;
    }
}

export { getBikemanAttendanceByMonth };
