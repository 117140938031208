import React, { useEffect, useState } from 'react';
import { ApplicationReceivedChart } from './Charts';
import { getAttendanceByMonth, GetAttendanceByMonthResponse} from 'Common/data/attendance/GetAttendanceByMonth';


interface AttendanceItem {
    date: string;
    BikemanId: number;
    createdAt: string;
    [key: string]: any;
}

const ApplicationReceived = () => {
    const [attendanceData, setAttendanceData] = useState<AttendanceItem[]>([]);
    const [ loading, setLoading] = useState(true);
    
    const getCurrentYearAndMonth =  () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.toLocaleString('default', { month: 'long' });
            return {year, month};
    }
    
    const getDaysInMonth = (year: number, month: number) => { 
        return new Date(year, month + 1, 0).getDate(); 
    };
    
    useEffect(() => {
        const fetchAttendanceData = async () => {
            const {year, month} = getCurrentYearAndMonth();
            try {
                const response: GetAttendanceByMonthResponse = await getAttendanceByMonth(year, month);
                setAttendanceData(response.results);
            } catch (error) {
                console.error('error fetching attendance data:', error);
            } finally{
                setLoading(false);
            }
        };

        fetchAttendanceData();
    }, []);
    
    const processAttendanceData = (data: AttendanceItem[]) => {
        const { year, month } = getCurrentYearAndMonth(); 
        const monthIndex = new Date(`${month} 1, ${year}`).getMonth(); 
        const daysInMonth = getDaysInMonth(year, monthIndex);
        
        const uniqueCheckIns: { [date: string]: { [bikemanId: number]: AttendanceItem } } = {};
        
        data.forEach(item => { 
            const date = item.date; 
            const bikemanId = item.BikemanId; 
            
            if (!uniqueCheckIns[date]) { 
                uniqueCheckIns[date] = {}; 
            } 
            
            // Store the most recent check-in for each bikemanId 
            if (!uniqueCheckIns[date][bikemanId] || new Date(item.createdAt) > new Date(uniqueCheckIns[date][bikemanId].createdAt)) { 
                uniqueCheckIns[date][bikemanId] = item; 
            } 
        }); 
        
        const attendanceByDay = Array.from({ length: daysInMonth }, (_, index) => {
            const date = new Date(year, monthIndex, index + 1).toISOString().split('T')[0];
            return { 
                date, 
                count: uniqueCheckIns[date] ? Object.keys(uniqueCheckIns[date]).length : 0
            };
        }); 
        

        // Sort by date 
        // attendanceByDay.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        
        // Generate labels and data for the chart 
        const labels = attendanceByDay.map(item => new Date(item.date).getDate().toString()); 
        const counts = attendanceByDay.map(item => item.count);
        
        return { labels, counts };
    }; 
    
    const { labels, counts } = processAttendanceData(attendanceData);
    
    if (loading) { 
        return <div>Loading...</div>; 
    }

    
    return (
        <React.Fragment>
            <div className="col-span-12 md:order-7 2xl:order-5 lg:col-span-12 2xl:col-span-6 2xl:row-span-2 card">
                <div className="card-body">
                    <div className="flex items-center gap-2 MB-3">
                        <h6 className="mb-0 text-15 grow">Attendance For The Month</h6>
                        
                    </div>
                    <ApplicationReceivedChart chartId="applicationReceivedChart" labels={labels} data={counts}/>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ApplicationReceived;
