import { AxiosResponse } from "axios";
import axiosInstance from "baseURL/api_helper";

interface CreateBikeData {
  name: string;
  model: string;
  plateNumber: string;
}

export interface CreateBikeResponse {
  status: any;
  results: any;
}

async function createBike(
  createBikeData: CreateBikeData
): Promise<CreateBikeResponse> {
  const apiUrl = "api/v1/admin/bike";

  try {
    const response: AxiosResponse<CreateBikeResponse> =
      await axiosInstance.post<CreateBikeResponse>(apiUrl, createBikeData);

    // Log the entire response data
    console.log("Create Bike Response:", response.data);

    // Return the response data
    return response.data;
  } catch (error: any) {
    console.error("Error creating bike:", error.message);
    // Handle errors here
    throw error;
  }
}

export { createBike };
