import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Search, Plus, Trash2, Pencil } from "lucide-react";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllBikes } from "Common/data/bike/GetAllBikes";
import { createBike } from "Common/data/bike/CreateBike";

// Define the Bike type
interface Bike {
  id: string;
  name: string;
  model: string;
  plateNumber: string;
}

const BikeList: React.FC = () => {
  const dispatch = useDispatch();
  const [bikes, setBikes] = useState<Bike[]>([]);
  const [eventData, setEventData] = useState<Bike | null>(null);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // Fetch bike data
  useEffect(() => {
    const fetchBikes = async () => {
      try {
        const response = await getAllBikes();
        setBikes(response.results);
      } catch (error) {
        console.error("Error fetching bikes:", error);
      }
    };
    fetchBikes();
  }, [dispatch]);

  // Delete Modal Toggle
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: Bike) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      // Implement bike deletion logic here
      setDeleteModal(false);
    }
  };

  // Update Data
  const handleUpdateDataClick = (ele: Bike) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // Validation with Formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      bikeName: eventData?.name || "",
      bikeModel: eventData?.model || "",
      plateNumber: eventData?.plateNumber || "",
    },
    validationSchema: Yup.object({
      bikeName: Yup.string().required("Please Enter Bike Name"),
      bikeModel: Yup.string().required("Please Enter Bike Model"),
      plateNumber: Yup.string().required("Please Enter Plate Number"),
    }),
    onSubmit: async (values) => {
      console.log("Form Values:", values);
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : "0",
          ...values,
        };
        // Implement update logic here
      } else {
        try {
          const newData = {
            name: values.bikeName,
            model: values.bikeModel,
            plateNumber: values.plateNumber,
          };
          const response = await createBike(newData);
          if (response.status === "success") {
            toast.success("You have successfully created a bike");
            // Optionally, refetch bikes to update the list
            const bikeResponse = await getAllBikes();
            setBikes(bikeResponse.results);
          } else if (response.status === "error") {
            toast.error("The plate number already exists!");
          }
        } catch (error) {
          toast.error("The plate number already exists!");
        }
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData(null);
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData(null);
      validation.resetForm();
    }
  }, [show, validation]);

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        enableColumnFilter: false,
      },
      {
        header: "Bike Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Bike Model",
        accessorKey: "model",
        enableColumnFilter: false,
      },
      {
        header: "Plate Number",
        accessorKey: "plateNumber",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-2">
            <Link
              to="#!"
              data-modal-target="addDepartmentModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/20"
              onClick={() => {
                const data = cell.row.original as Bike;
                handleUpdateDataClick(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            <Link
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-red-500 dark:hover:text-red-500 hover:bg-red-100 dark:hover:bg-red-500/20"
              onClick={() => {
                const data = cell.row.original as Bike;
                onClickDelete(data);
              }}
            >
              <Trash2 className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BreadCrumb title="Bike List" pageTitle="Bike Management" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h6 className="text-15 grow">Bike List</h6>
            <div className="shrink-0">
              <Link
                to="#!"
                data-modal-target="addDepartmentModal"
                type="button"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                onClick={toggle}
              >
                <Plus className="inline-block size-4" />{" "}
                <span className="align-middle">Add Bike</span>
              </Link>
            </div>
          </div>
          {bikes && bikes.length > 0 ? (
            <TableContainer
              isPagination={true}
              columns={columns || []}
              data={bikes || []}
              customPageSize={5}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right"
              thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">
                  We've searched more than 5+ bikes. We did not find any bikes
                  for your search.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Bike" : "Add Bike"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="bikeNameInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Bike Name
                </label>
                <input
                  type="text"
                  id="bikeNameInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Name of bike"
                  name="bikeName"
                  onChange={validation.handleChange}
                  value={validation.values.bikeName || ""}
                />
                {validation.touched.bikeName && validation.errors.bikeName ? (
                  <p className="text-red-400">{validation.errors.bikeName}</p>
                ) : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="bikeModelInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Bike Model
                </label>
                <input
                  type="text"
                  id="bikeModelInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Model of bike"
                  name="bikeModel"
                  onChange={validation.handleChange}
                  value={validation.values.bikeModel || ""}
                />
                {validation.touched.bikeModel && validation.errors.bikeModel ? (
                  <p className="text-red-400">{validation.errors.bikeModel}</p>
                ) : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="plateNumberInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Plate Number
                </label>
                <input
                  type="text"
                  id="plateNumberInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Plate number"
                  name="plateNumber"
                  onChange={validation.handleChange}
                  value={validation.values.plateNumber || ""}
                />
                {validation.touched.plateNumber &&
                validation.errors.plateNumber ? (
                  <p className="text-red-400">
                    {validation.errors.plateNumber}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDepartmentModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Add Bike"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default BikeList;
