import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import BreadCrumb from 'Common/BreadCrumb';
import Widgets from './Widgets';
import EmployeePerformance from './EmployeePerformance';
import UpcomingScheduled from './UpcomingScheduled';
import TotalProjects from './TotalProjects';
import UpcomingInterview from './UpcomingInterview';
import RecentPayroll from './RecentPayroll';
import { getAttendanceByDate } from 'Common/data/attendance/GetAttendanceByDate'; 
import { getAllBikemen } from 'Common/data/bike/GetAllBikemen';
import { calculateAttendanceStats } from './AttendanceStats';

const HRDashboard = () => {

    const [stats, setStats] = useState({ presentCount: 0, absentCount: 0, halfDayCount: 0 });
    const [totalStaffCount, setTotalStaffCount] = useState<number>(0);

    // Retrieve user data from local storage
    const userDataString = localStorage.getItem('authUser') || '{}';
    const userData = JSON.parse(userDataString);

    // Extract the token from the user data
    const authFirstName = userData.results.firstName;
    const authLastName = userData.results.lastName;

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const date = new Date().toISOString().split('T')[0];
                const [attendanceResponse, bikemenResponse] = await Promise.all([ 
                    getAttendanceByDate(date), 
                    getAllBikemen() 
                ]);

                const totalStaff = bikemenResponse.results.length; 
                setTotalStaffCount(totalStaff);

                const { presentCount, halfDayCount, absentCount } = calculateAttendanceStats(attendanceResponse.results, totalStaff); 
                setStats({ presentCount, absentCount, halfDayCount });
            } catch (error) 
                { console.error('Error fetching data:', error); 
            }
        }; 
        
        fetchAllData(); 
    }, []);

    return (
        <React.Fragment>
            <BreadCrumb title='ADMIN' pageTitle='Dashboards' />
            <div className="grid grid-cols-12 2xl:grid-cols-12 gap-x-5">
                <div className="col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6">
                    <h5 className="mb-2">Welcome {authFirstName} {authLastName} 🎉</h5>
                    <p className="mb-5 text-slate-500 dark:text-zink-200">Easily manage your riders. See who's attending, who's missing rides, and how many are using half-days each month. Spot trends to understand how your rider base is growing.
                 <br />
                 <Link to="apps-hr-employee" className="text-blue-500">View Riders Information</Link></p>
                </div>
                <div className="col-span-12 md:order-2 xl:col-span-4 2xl:col-start-9 card">
                    <div className="p-4">
                        <div className="grid grid-cols-3">
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={stats.absentCount} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Absent</p>
                            </div>
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={stats.presentCount} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Present</p>
                            </div>
                            <div className="px-4 text-center ltr:border-r rtl:border-l border-slate-200 dark:border-zink-500 ltr:last:border-r-0 rtl:last:border-l-0">
                                <h6 className="mb-1 font-bold">
                                    <CountUp end={stats.halfDayCount} className="counter-value" />
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">Half-Day</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Widgets totalStaffCount={totalStaffCount} />
                <EmployeePerformance />
                <UpcomingScheduled />
                <TotalProjects />
                <UpcomingInterview />
                <RecentPayroll />

            </div>
        </React.Fragment>
    );
};

export default HRDashboard;
