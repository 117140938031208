import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface GetAllBikemenResponse {
    status_code: number;
    results: any;
}

async function getAllBikemen(): Promise<GetAllBikemenResponse> {
    const apiUrl = 'api/v1/admin/bikemen';

    // // Retrieve token from localStorage
    // const authUser: any = localStorage.getItem("authUser");
    // const token = JSON.parse(authUser) ? JSON.parse(authUser).token : null;

    try {
        const response: AxiosResponse<GetAllBikemenResponse> = await axiosInstance.get<GetAllBikemenResponse>(apiUrl, {
            // headers: {
            //     Authorization: token ? `Bearer ${token}` : undefined // Set authorization header if token exists
            // }
        });

        // Log the entire response data
        console.log('Get All Bikemen Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error fetching all bikemen:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getAllBikemen };
