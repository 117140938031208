import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { adminSignup, AdminSignupData, AdminSignupResponse } from "Common/data/adminAuth/AdminSignup";

const CreateSubAdminTabs = () => {
    const navigate = useNavigate(); // Use the useNavigate hook
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

    const validation = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            password: "",
            role: "admin", // Set default role to "admin"
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please Enter Your First Name"),
            lastName: Yup.string().required("Please Enter Your Last Name"),
            email: Yup.string().email().required("Please Enter Your Email"),
            phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            const { firstName, lastName, email, phoneNumber, password, role } = values;
            try {
                const response: AdminSignupResponse = await adminSignup({
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    password,
                    role,
                });

                if (response.status_code === "0") {
                    setSuccessMessage("Account has been successfully created.");
                    setErrorMessage("");
                } else {
                    setErrorMessage(response.message || "Failed to create account. Please try again.");
                    setSuccessMessage("");
                }
            } catch (error: any) {
                setErrorMessage(error.response.data.message || "An error occurred. Please try again.");
                setSuccessMessage("");
                console.error('Error during form submission:', error.response.data.message);
            } finally {
                setSubmitting(false);
            }
        },
    });

    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">Create Sub Admin</h6>
                    <form className="mt-10" id="createSubAdminForm" onSubmit={validation.handleSubmit}>
                        <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                            <div className="xl:col-span-6">
                                <label htmlFor="firstname-field" className="inline-block mb-2 text-base font-medium">First Name<span className="text-red-500">*</span></label>
                                <div className="relative">
                                    <input type="text" id="firstname-field" name="firstName" className="form-input border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200" placeholder="Enter First Name of Sub Admin" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.firstName || ""} />
                                    {validation.touched.firstName && validation.errors.firstName ? (
                                        <div id="firstname-error" className="mt-1 text-sm text-red-500">{validation.errors.firstName}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="lastname-field" className="inline-block mb-2 text-base font-medium">Last Name<span className="text-red-500">*</span></label>
                                <div className="relative">
                                    <input type="text" id="lastname-field" name="lastName" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.lastName || ""} className="form-input border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200" placeholder="Enter Last Name of Sub Admin" />
                                    {validation.touched.lastName && validation.errors.lastName ? (
                                        <div id="lastname-error" className="mt-1 text-sm text-red-500">{validation.errors.lastName}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="email-field" className="inline-block mb-2 text-base font-medium">Email Address<span className="text-red-500">*</span></label>
                                <div className="relative">
                                    <input type="email" id="email-field" name="email" className="form-input border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200" placeholder="Enter Email" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.email || ""} />
                                    {validation.touched.email && validation.errors.email ? (
                                        <div id="email-error" className="mt-1 text-sm text-red-500">{validation.errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="password-field" className="inline-block mb-2 text-base font-medium">Password<span className="text-red-500">*</span></label>
                                <div className="relative">
                                    <input type={showPassword ? "text" : "password"} id="password-field" name="password" className="form-input border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200" placeholder="Enter Password" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.password || ""} />
                                    <button className="absolute top-2 ltr:right-4 rtl:left-4" type="button" onClick={togglePasswordVisibility}>
                                        <i className={showPassword ? "align-middle ri-eye-off-fill text-slate-500 dark:text-zinc-200" : "align-middle ri-eye-fill text-slate-500 dark:text-zinc-200"}></i>
                                    </button>
                                    {validation.touched.password && validation.errors.password ? (
                                        <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.password}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="phonenumber-field" className="inline-block mb-2 text-base font-medium">Phone Number<span className="text-red-500">*</span></label>
                                <div className="relative">
                                    <input type="text" id="phonenumber-field" name="phoneNumber" className="form-input border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200" placeholder="Enter Phone Number" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.phoneNumber || ""} />
                                    {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                                        <div id="phonenumber-error" className                                        ="mt-1 text-sm text-red-500">{validation.errors.phoneNumber}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end mt-5">
                            <button type="submit" disabled={validation.isSubmitting} className="btn text-white bg-green-500 border-green-500 hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100">
                                {validation.isSubmitting ? 'Creating...' : 'Create'}
                            </button>
                        </div>
                    </form>
                    {successMessage && (
                        <div className="mt-4 px-4 py-2 text-sm text-green-500 border border-transparent rounded-md bg-green-50">
                            <strong>Success:</strong> {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="mt-4 px-4 py-2 text-sm text-red-500 border border-transparent rounded-md bg-red-50">
                            <strong>Error:</strong> {errorMessage}
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default CreateSubAdminTabs;

