import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "Common/BreadCrumb";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import PersonalTabs from "./PersonalTabs";
import ChangePasswordTabs from "./ChangePasswordTabs";
import CreateSubAdminTabs from "./CreateSubAdminTabs";

const Settings = () => {
    const [authRole, setAuthRole] = useState("");

    useEffect(() => {
        // Retrieve user data from local storage
        const userDataString = localStorage.getItem('authUser') || '{}';
        const userData = JSON.parse(userDataString);
        const role = userData.results ? userData.results.role : '';
        setAuthRole(role);
    }, []);

    return (
        <React.Fragment>
            <BreadCrumb title='Account Settings' pageTitle='Settings' />
            <Tab.Container defaultActiveKey="personalTabs">
                <div className="card">
                    <div className="card-body !py-0">
                        <Nav className="flex flex-wrap w-full text-sm font-medium text-center nav-tabs">
                            <Nav.Item eventKey="personalTabs" className="group">
                                <Link to="#/pages-account-settings" data-tab-toggle data-target="personalTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Personal Info</Link>
                            </Nav.Item>
                            <Nav.Item eventKey="changePasswordTabs" className="group">
                                <Link to="#/pages-account-settings" data-tab-toggle data-target="changePasswordTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Change Password</Link>
                            </Nav.Item>
                            {authRole === "super_admin" && (
                                <Nav.Item eventKey="createSubAdminTabs" className="group">
                                    <Link to="#/pages-account-settings" data-tab-toggle data-target="createSubAdminTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Create Sub Admin</Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </div>
                </div>
                <Tab.Content>
                    <Tab.Pane eventKey="personalTabs">
                        <PersonalTabs />
                    </Tab.Pane>
                    <Tab.Pane eventKey="changePasswordTabs">
                        <ChangePasswordTabs />
                    </Tab.Pane>
                    {authRole === "super_admin" && (
                        <Tab.Pane eventKey="createSubAdminTabs">
                            <CreateSubAdminTabs />
                        </Tab.Pane>
                    )}
                </Tab.Content>
            </Tab.Container>
        </React.Fragment>
    );
};

export default Settings;
