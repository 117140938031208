import React, { useState } from "react";
import { updateAdminPassword, UpdateAdminPasswordData, UpdateAdminPasswordResponse } from 'Common/data/adminAuth/UpdateAdminPassword';

const ChangePasswordTabs = () => {
    const [formData, setFormData] = useState<UpdateAdminPasswordData>({
        passwordCurrent: '',
        password: '',
        passwordConfirm: ''
    });

    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');

        try {
            const response: UpdateAdminPasswordResponse = await updateAdminPassword(formData);
            console.log('Password updated successfully', response);
            setSuccessMessage('Password updated successfully!');
        } catch (error) {
            console.error('Error updating password', error);
            setErrorMessage('Error updating password. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">Change Password</h6>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                            <div className="xl:col-span-4">
                                <label htmlFor="oldPasswordInput" className="inline-block mb-2 text-base font-medium">Old Password*</label>
                                <div className="relative">
                                    <input type="password" id="passwordCurrent" value={formData.passwordCurrent} onChange={handleInputChange} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500" placeholder="Enter current password" />
                                    <button className="absolute top-2 ltr:right-4 rtl:left-4 " type="button"><i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i></button>
                                </div>
                            </div>
                            <div className="xl:col-span-4">
                                <label htmlFor="newPasswordInput" className="inline-block mb-2 text-base font-medium">New Password*</label>
                                <div className="relative">
                                    <input type="password" id="password" value={formData.password} onChange={handleInputChange} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500" placeholder="Enter new password" />
                                    <button className="absolute top-2 ltr:right-4 rtl:left-4 " type="button"><i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i></button>
                                </div>
                            </div>
                            <div className="xl:col-span-4">
                                <label htmlFor="confirmPasswordInput" className="inline-block mb-2 text-base font-medium">Confirm Password*</label>
                                <div className="relative">
                                    <input type="password" id="passwordConfirm" value={formData.passwordConfirm} onChange={handleInputChange} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500" placeholder="Confirm password" />
                                    <button className="absolute top-2 ltr:right-4 rtl:left-4 " type="button"><i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i></button>
                                </div>
                            </div>
                            <div className="flex items-center xl:col-span-6">

                            </div>
                            <div className="flex justify-end xl:col-span-6">
                                <button type="submit" className={`text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100`} disabled={loading}>
                                    {loading ? 'Changing Password...' : 'Change Password'}
                                </button>
                            </div>
                        </div>
                    </form>
                    {successMessage && (
                        <div className="mt-4 px-4 py-2 text-sm text-green-500 border border-transparent rounded-md bg-green-50">
                            <strong>Success:</strong> {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="mt-4 px-4 py-2 text-sm text-red-500 border border-transparent rounded-md bg-red-50">
                            <strong>Error:</strong> {errorMessage}
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ChangePasswordTabs;
