import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import Flatpickr from 'react-flatpickr';
import CountUp from 'react-countup';
// Icons
import { Search, Check, X, Clock, CalendarClock, UsersRound, UserRoundX, UserRoundCheck, Briefcase, CalendarRange, UserCheck } from 'lucide-react';
import TableContainer from 'Common/TableContainer';
import { Link } from 'react-router-dom';
import Modal from 'Common/Components/Modal';
import Select from 'react-select';
import { getAttendanceByMonth } from 'Common/data/attendance/GetAttendanceByMonth';
import { preprocessData, calculateStatus } from './converter';

import { MainAttendanceData } from 'Common/data';

// react-redux
import filterDataBySearch from 'Common/filterDataBySearch';

const MainAttendance = () => {
    const [data, setData] = useState<any>([]);
    const [show, setShow] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date[]>([new Date()]);

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear - 10; i <= currentYear; i++) {
            years.push({ value: i, label: i.toString() });
        }
        return years;
    }, []);

    const fetchAttendanceData = async (selectedYear: number, selectedMonth: string) => { 
        try {
            const response = await getAttendanceByMonth(selectedYear, selectedMonth); 
            const processedData = preprocessData(response.results); 
            console.log('Processed Data:', processedData);
            setData(processedData); 
        } catch (error) { 
            console.error('Error fetching attendance data:', error); 
        } 
    };

    useEffect(() => {
        const selectedYear = selectedDate[0]?.getFullYear();
        const selectedMonth = monthNames[selectedDate[0]?.getMonth()];
        fetchAttendanceData(selectedYear, selectedMonth);
    }, [selectedDate]);

    const handleYearChange = (selectedOption: any) => {
        const newDate = new Date(selectedDate[0]);
        newDate.setFullYear(selectedOption.value);
        setSelectedDate([newDate]);
    };

    const handleMonthChange = (selectedOption: any) => {
        const newDate = new Date(selectedDate[0]);
        newDate.setMonth(monthNames.indexOf(selectedOption.value));
        setSelectedDate([newDate]);
    };

    const monthOptions = monthNames.map((month, index) => ({
        value: month,
        label: month,
    }));

    useEffect(() => { 
        const fetchAttendanceData = async () => { 
            try {
                const response = await getAttendanceByMonth(2024, 'June'); // Update with correct year and month 
                const processedData = preprocessData(response.results); 
                console.log('Processed Data:', processedData);
                setData(processedData); 
            } catch (error) { 
                console.error('Error fetching attendance data:', error); 
            } 
        }; 
    
        fetchAttendanceData(); 
    }, []);

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['Bikeman.firstName', 'Bikeman.lastName'];
        filterDataBySearch(data, search, keysToSearch, setData);
    };

    const Attendance = ({ checkIn, checkOut }: any) => {
        const status = calculateStatus(checkIn, checkOut);
        switch (status) {    
            case "CheckedIn":
                return (
                    <UserCheck className="size-4 text-yellow-500" />
                );
            case "FullDay":
                return (
                    <Link 
                        to="#!" 
                        className="text-green-500 transition-all duration-200 ease-linear hover:text-green-600" 
                        onClick={() => setShow(true)}
                    >
                        <Check className="size-4" />
                    </Link>
                );
            case "HalfDay":
                return (
                    <Clock className="size-4 text-gray-500 mr-1" />
                );
            default:
                return (
                    <div className="flex items-center">
                        <span>-</span>
                    </div>
                );
        }
    };

    const columns = useMemo(() => [
        {
            header: "Bikemen Name",
            accessorKey: "Bikeman",
            enableColumnFilter: false,
            cell: (cell: any) => {
                const {firstName, lastName} = cell.row.original.Bikeman || {};
                return <span>{firstName} {lastName}</span>;
            }
        },
        ...Array.from({ length: 30 }, (_, i) => ({
            header: String(i + 1).padStart(2, '0'),
            accessorKey: `Day${i + 1}`,
            enableSorting: false,
            enableColumnFilter: false,
            cell: (cell: any) => {
                const dayData = cell.row.original.days[i];
                return <Attendance checkIn={dayData.checkIn} checkOut={dayData.checkOut} />
            }
        }))
    ], []);

    return (
        <React.Fragment>
            <BreadCrumb title='Main Attendance' pageTitle='Attendance' />
            <div className="card">
                <div className="card-body">
                    <div className="grid grid-cols-1 gap-5 mb-5 xl:grid-cols-12 justify-end">
                        <div className="xl:col-span-3">
                            <div className="relative">
                                <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for ..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                            </div>
                        </div>
                        <div className="xl:col-span-3">
                            <Select
                                className="border-none focus:outline-none focus:border-none dark:text-zink-100 dark:bg-zink-700 dark:focus:border-none"
                                options={yearOptions}
                                defaultValue={yearOptions.find(option => option.value === selectedDate[0]?.getFullYear())}
                                onChange={handleYearChange}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        border: '1',
                                        boxShadow: '1',
                                    }),
                                }}
                            />
                        </div>
                        <div className="xl:col-span-3">
                            <Select
                                className="border-none focus:outline-none focus:border-none dark:text-zink-100 dark:bg-zink-700 dark:focus:border-none"
                                options={monthOptions}
                                defaultValue={monthOptions[selectedDate[0]?.getMonth()]}
                                onChange={handleMonthChange}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        border: '1',
                                        boxShadow: '',
                                    }),
                                }}
                            />
                        </div>
                    </div>

                    {data && data.length > 0 ?
                        <TableContainer
                            isPagination={false}
                            columns={(columns || [])}
                            data={(data || [])}
                            customPageSize={10}
                            divclassName="overflow-x-auto"
                            tableclassName="w-full whitespace-nowrap"
                            theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                            thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                            tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                            />
                            :
                            (<div className="noresult">
                                <div className="py-6 text-center">
                                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                    <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched more than 10+ Attendance We did not find any Attendance for you search.</p>
                                </div>
                            </div>)}
                </div>    
            </div>
    
            {/* Main Attendance Modal */}
    
            <Modal show={show} onHide={() => setShow(!show)} modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
                <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                    <Modal.Title className="text-16">Attendance Info (05 June, 2023)</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                    <div className="rounded-md bg-slate-100 dark:bg-zink-500/50">
                        <div className="p-3">
                            <p className="mb-1 text-slate-500 dark:text-zink-200">Check In Date & Time</p>
                            <h6>Monday, 05 June, 2023 <b>08:29 AM</b></h6>
                        </div>
                        <div className="p-5">
                            <div className="flex items-center justify-center size-24 mx-auto bg-white rounded-full dark:bg-zink-600 text-16 outline outline-white dark:outline-zink-600 outline-offset-4">
                                <b>08:15</b> <small className="ltr:ml-1 rtl:mr-1">Hrs</small>
                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    };
    
    export default MainAttendance;
