// dashboard
import HRDashboard from "pages/Dashboards/HR";

// HR Management
import EmployeeList from "pages/HRManagement/EmployeeList";
import Holidays from "pages/HRManagement/Holidays";
import LeaveManageEmployee from "pages/HRManagement/Leaves Manage/LeaveManageEmployee";
import AddLeaveEmployee from "pages/HRManagement/Leaves Manage/AddLeaveEmployee";
import LeaveManageHR from "pages/HRManagement/Leaves Manage/LeaveManageHR";
import AddLeaveHR from "pages/HRManagement/Leaves Manage/AddLeaveHR";
import AttendanceHR from "pages/HRManagement/Attendance/AttendanceHR";
import MainAttendance from "pages/HRManagement/Attendance/MainAttendance";
import Departments from "pages/HRManagement/Departments";
import Estimates from "pages/HRManagement/Sales/Estimates";
import Payments from "pages/HRManagement/Sales/Payments";
import Expenses from "pages/HRManagement/Sales/Expenses";
import EmployeeSalary from "pages/HRManagement/Payroll/EmployeeSalary";
import Payslip from "pages/HRManagement/Payroll/Payslip";
import CreatePayslip from "pages/HRManagement/Payroll/CreatePayslip";

// auth
import Basic from "pages/AuthenticationInner/Login/Basic";

//Register
import BasicRegister from "pages/AuthenticationInner/Register/Basic";

// EmailVerify
import BasicEmailVerify from "pages/AuthenticationInner/VerifyEmail/Basic";

// TwoSteps
import BasicTwoSteps from "pages/AuthenticationInner/TwoSteps/Basic";

// Logout
import BasicLogout from "pages/AuthenticationInner/Logout/Basic";

// Reset Password
import BasicResetPassword from "pages/AuthenticationInner/ResetPassword/Basic";

// Create Password
import BasicCreatePassword from "pages/AuthenticationInner/CreatePassword/Basic";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Pages404 from "pages/AuthenticationInner/Pages404";
import Register from "pages/Authentication/Register";
import UserProfile from "pages/Authentication/UserProfile";
import ComingSoon from "pages/AuthenticationInner/ComingSoon";
import Offline from "pages/AuthenticationInner/Offline";
import Maintenance from "pages/AuthenticationInner/Maintenance";
import Settings from "pages/Pages/Settings";

import Account from "pages/Pages/Account/index";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: HRDashboard },
  { path: "/dashboard", component: HRDashboard },

  // HR Management
  { path: "/apps-hr-employee", component: EmployeeList },
  { path: "/apps-hr-holidays", component: Holidays },
  { path: "/apps-hr-leave-employee", component: LeaveManageEmployee },
  { path: "/apps-hr-create-leave-employee", component: AddLeaveEmployee },
  { path: "/apps-hr-leave", component: LeaveManageHR },
  { path: "/apps-hr-create-leave", component: AddLeaveHR },
  { path: "/apps-hr-attendance/:id", component: AttendanceHR },
  { path: "/apps-hr-attendance-main", component: MainAttendance },
  { path: "/apps-hr-department", component: Departments },
  { path: "/apps-hr-sales-estimates", component: Estimates },
  { path: "/apps-hr-sales-payments", component: Payments },
  { path: "/apps-hr-sales-expenses", component: Expenses },
  { path: "/apps-hr-payroll-employee-salary", component: EmployeeSalary },
  { path: "/apps-hr-payroll-payslip", component: Payslip },
  { path: "/apps-hr-payroll-create-payslip", component: CreatePayslip },

  // pages
  { path: "/pages-account", component: Account },
  { path: "/pages-account-settings", component: Settings },

  // profile
  { path: "/user-profile", component: UserProfile },
];

const publicRoutes = [

  // auth
  { path: "/auth-login-basic", component: Basic },

  // Register
  { path: "/auth-register-basic", component: BasicRegister },

  // Verify Email
  { path: "/auth-verify-email-basic", component: BasicEmailVerify },

  // two steps
  { path: "/auth-two-steps-basic", component: BasicTwoSteps },

  // logout
  { path: "/auth-logout-basic", component: BasicLogout },

  //Reset Password
  { path: "/auth-reset-password-basic", component: BasicResetPassword },

  //Create Password
  { path: "/auth-create-password-basic", component: BasicCreatePassword },

  // coming soon
  { path: "/pages-coming-soon", component: ComingSoon },

  // Error
  { path: "/pages-offline", component: Offline },
  { path: "/pages-404", component: Pages404 },

  // Maintenance
  { path: "/pages-maintenance", component: Maintenance },


  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },

]

export { authProtectedRoutes, publicRoutes };
