import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

interface UpdateAdminProfileData {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    state: string;
    profile_image_url: File;
}

export interface UpdateAdminProfileResponse {
    status_code: number;
    results: any;
}

async function updateAdminProfile(updateAdminProfileData: UpdateAdminProfileData): Promise<UpdateAdminProfileResponse> {
    const apiUrl = 'api/v1/auth/updateMe';

    // Create form data
    const formData = new FormData();
    formData.append('firstName', updateAdminProfileData.firstName);
    formData.append('lastName', updateAdminProfileData.lastName);
    formData.append('phoneNumber', updateAdminProfileData.phoneNumber);
    formData.append('state', updateAdminProfileData.state);
    formData.append('profile_image_url', updateAdminProfileData.profile_image_url);

    try {
        const response: AxiosResponse<UpdateAdminProfileResponse> = await axiosInstance.patch<UpdateAdminProfileResponse>(apiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        // Log the entire response data
        console.log('Update Admin Profile Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error during admin profile update:', error.message);
        // Handle errors here
        throw error;
    }
}

export { updateAdminProfile };
