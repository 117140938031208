import React, { useEffect, useState } from "react";
import { Trash2 } from "lucide-react";
import { getAllSubAdmins, SubAdmin } from "Common/data/adminAuth/SubAdmins";
import { deleteSubAdmin, DeleteSubAdminResponse } from "Common/data/adminAuth/DelSubAdmin";
import DeleteModal from "./DeleteModal"; // Adjust the import path accordingly

const Documents = () => {
    const [subAdmins, setSubAdmins] = useState<SubAdmin[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    const fetchSubAdmins = async () => {
        try {
            const response = await getAllSubAdmins();
            if (response && response.length > 0) {
                setSubAdmins(response);
            } else {
                console.error("No sub-admins found or error in API response.");
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching sub-admins:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubAdmins();
    }, []);

    const handleDelete = async () => {
        if (deleteId) {
            try {
                setDeleteLoading(true);
                const response: DeleteSubAdminResponse = await deleteSubAdmin(deleteId);

                if (response.status_code === 200) {
                    console.log("Sub-admin deleted successfully");
                    // Update the state to remove the deleted sub-admin
                    setSubAdmins((prevSubAdmins) => prevSubAdmins.filter(subAdmin => subAdmin.id !== deleteId));
                } else {
                    console.error("Failed to delete sub-admin:", response.message);
                }
            } catch (error) {
                console.error("Error deleting sub-admin:", error);
            } finally {
                setDeleteLoading(false);
                setShowDeleteModal(false);
                setDeleteId(null);
            }
        }
    };

    const handleShowDeleteModal = (id: number) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteId(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <div className="flex items-center gap-3 mb-4">
                <h5 className="underline">Sub Admins</h5>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full align-middle border-separate whitespace-nowrap border-spacing-y-1">
                    <thead className="text-left bg-white dark:bg-zink-700">
                        <tr>
                            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Name</th>
                            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Phone Number</th>
                            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Created At</th>
                            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subAdmins.map((subAdmin) => (
                            <tr key={subAdmin.id} className="bg-white dark:bg-zink-700">
                                <td className="px-3.5 py-2.5 border-y border-transparent">
                                    <div className="flex items-center">
                                        <img
                                            src={subAdmin.profile_image_url}
                                            alt={`${subAdmin.firstName} ${subAdmin.lastName}`}
                                            className="w-10 h-10 rounded-full object-cover mr-2"
                                        />
                                        <span>{`${subAdmin.firstName} ${subAdmin.lastName}`}</span>
                                    </div>
                                </td>
                                <td className="px-3.5 py-2.5 border-y border-transparent">{subAdmin.phoneNumber}</td>
                                <td className="px-3.5 py-2.5 border-y border-transparent">{subAdmin.createdAt}</td>
                                <td className="px-3.5 py-2.5 border-y border-transparent">
                                    <div className="flex items-center justify-end gap-2">
                                        <button
                                            className="flex items-center justify-center size-8 transition-all duration-150 ease-linear rounded-md bg-slate-100 hover:bg-slate-200 dark:bg-zink-600 dark:hover:bg-zink-500"
                                            onClick={() => handleShowDeleteModal(subAdmin.id)}
                                        >
                                            <Trash2 className="size-3" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <DeleteModal
                show={showDeleteModal}
                onClose={handleCloseDeleteModal}
                onDelete={handleDelete}
                deleteLoading={deleteLoading}
            />
        </React.Fragment>
    );
};

export default Documents;
