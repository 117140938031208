import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

interface MarkAttendanceData {
    bikemanId: string;
    bikeId: string;
    status: string;
    comment: string;
    worktimeStatus: string;
}

export interface MarkAttendanceResponse {
    status_code: number;
    results: any;
}

async function markAttendance(markAttendanceData: MarkAttendanceData): Promise<MarkAttendanceResponse> {
    const apiUrl = 'api/v1/attendances/attendance';

    try {
        const response: AxiosResponse<MarkAttendanceResponse> = await axiosInstance.post<MarkAttendanceResponse>(apiUrl, markAttendanceData);

        // Log the entire response data
        console.log('Mark Attendance Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error marking attendance:', error.message);
        // Handle errors here
        throw error;
    }
}

export { markAttendance };
