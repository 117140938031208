import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { getAllBikes, Bike } from 'Common/data/bike/GetAllBikes'; // Update the import path to the correct location
import { assignBikeToBikeman, AssignBikeToBikemanResponse } from 'Common/data/bike/AssignBikeToBikeman'; // Update the import path
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define the app element for react-modal
Modal.setAppElement('#root');

interface AssignBikeModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    bikemanId?: number; // Optional bikemanId prop
    onAssignBike: (bikeId: number) => void;
}

const AssignBikeModal: React.FC<AssignBikeModalProps> = ({ isOpen, onRequestClose, bikemanId = 1, onAssignBike }) => {
    const [bikes, setBikes] = useState<Bike[]>([]);
    const [selectedBike, setSelectedBike] = useState<Bike | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchBikes = async () => {
            try {
                const response = await getAllBikes();
                setBikes(response.results);
            } catch (error) {
                console.error('Error fetching bikes:', error);
            }
        };

        fetchBikes();
    }, []);

    const handleAssignBike = async () => {
        if (selectedBike) {
            setLoading(true);
            try {
                const assignData = { bikemanId, bikeId: selectedBike.id };
                const response: AssignBikeToBikemanResponse = await assignBikeToBikeman(assignData);
                if (response.status === "success") {
                    // If API call is successful, proceed with the local state update and close the modal
                    onAssignBike(selectedBike.id);
                    onRequestClose();
                    toast.success('Bike assigned successfully!');
                } else {
                    // Handle API error responses
                    toast.error('Failed to assign bike. Please try again later.');
                }
            } catch (error) {
                console.error('Error assigning bike:', error);
                toast.error('Failed to assign bike. Please try again later.');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50"
            overlayClassName="Overlay"
        >
            <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium">Assign Bike</h2>
                    <button onClick={onRequestClose} className="text-gray-500 hover:text-red-500">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <Select
                    options={bikes.map(bike => ({ value: bike.id, label: `${bike.name} (${bike.plateNumber})` }))}
                    onChange={(selectedOption) =>
                        setSelectedBike(bikes.find(bike => bike.id === selectedOption?.value) || null)
                    }
                    placeholder="Select a bike"
                />
                <div className="flex justify-end mt-6">
                    <button
                        onClick={handleAssignBike}
                        className="button bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center"
                        disabled={loading}
                    >
                        {loading && (
                            <div className="inline-block w-4 h-4 border-2 border-white rounded-full animate-spin border-l-transparent"></div>
                        )}
                        {loading ? 'Assigning...' : 'Assign Bike'}
                    </button>
                </div>
            </div>
            <ToastContainer position="bottom-right" />
        </Modal>
    );
};

export default AssignBikeModal;
