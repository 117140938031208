import React from "react";

// Images
import avatar6 from "assets/images/users/avatar-6.png";
import birthday from "assets/images/birthday.png";

import { RecentPayrollData } from "Common/data";

// Icons
import { MoveUpRight, MoveDownLeft, MoreVertical } from 'lucide-react';

// SimpleBar
import SimpleBar from 'simplebar-react';
import { Dropdown } from "Common/Components/Dropdown";
import { Link } from "react-router-dom";

const RecentPayroll = () => {

    const Status = ({ item }: any) => {
        switch (item) {
            case "Paid":
                return (<span className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">{item}</span>);
            case "Pending":
                return (<span className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">{item}</span>);
            case "Cancelled":
                return (<span className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent">{item}</span>);
            default:
                return (<span className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">{item}</span>);
        }
    };


    return (
        <React.Fragment>
           
        </React.Fragment>
    );
};

export default RecentPayroll;