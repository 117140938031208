import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

interface LoginData {
    email: string;
    password: string;
}

export interface LoginResponse {
    status_code: number;
    results: any;
}

async function login(loginData: LoginData): Promise<LoginResponse> {
    const apiUrl = 'api/v1/auth/login';

    try {
        const response: AxiosResponse<LoginResponse> = await axiosInstance.post<LoginResponse>(apiUrl, loginData);

        // Log the entire response data
        console.log('Login Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error during login:', error.message);
        // Handle errors here
        throw error;
    }
}

export { login };
