import React, { useMemo, useState } from 'react';
import TableContainer from 'Common/TableContainer';
import { Link } from 'react-router-dom';
import { EmployeePerformanceData } from 'Common/data';

// Icons
import { Search, Trash2, Pencil } from 'lucide-react';
import filterDataBySearch from 'Common/filterDataBySearch';

const EmployeePerformance = () => {

    const [data, setData] = useState(EmployeePerformanceData);

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['employeeId', 'name', 'email', 'designation', 'performance'];
        filterDataBySearch(EmployeePerformanceData, search, keysToSearch, setData);
    };

     const columns = useMemo(() => [
        {
            header: (<input id="productsCheckAll" className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />),
            enableColumnFilter: false,
            enableSorting: true,
            id: "checkAll",
            cell: (cell: any) => {
                return (<input id={cell.row.original.id} className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />);
            }
        },
        {
            header: "ID",
            accessorKey: "employeeId",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <>
                    <Link to="#">{cell.getValue()}</Link>
                </>
            ),
        },
        {
            header: "Name",
            accessorKey: "name",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <div className="flex gap-2">
                    <div className="size-10 bg-green-100 rounded-full dark:bg-green-500/20 shrink-0">
                        <img src={cell.row.original.img} alt="" className="h-10 rounded-full" />
                    </div>
                    <div className="grow">
                        <h6>{cell.getValue()}</h6>
                        <p className="text-slate-500 dark:text-zink-200">{cell.row.original.email}</p>
                    </div>
                </div>
            ),
        },
        {
            header: "Designation",
            accessorKey: "designation",
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Performance",
            accessorKey: "performance",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <span className={`${cell.getValue() === "Good" ? "text-green-500" : "text-red-500"}`}>
                    {cell.getValue()}
                </span>
            ),
        },
        {
            header: "Status",
            accessorKey: "isActive",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                cell.getValue() ?
                    <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">Active</span> :
                    <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">Disabled</span>
            ),
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <div className="flex gap-2">
                    <Link to="#" className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/20"><Pencil className="size-4" /></Link>
                    <Link to="#" className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-red-500 dark:hover:text-red-500 hover:bg-red-100 dark:hover:bg-red-500/20"><Trash2 className="size-4" /></Link>
                </div>
            ),
        }
    ], []
    ); 

    return (
        <React.Fragment>
        
        </React.Fragment> 
    );
}; 

export default EmployeePerformance;
