import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

interface EmailVerificationData {
    token: string;
}

export interface EmailVerificationResponse {
    status_code: number;
    results: any;
}

async function emailVerification(emailVerificationData: EmailVerificationData): Promise<EmailVerificationResponse> {
    const apiUrl = 'api/v1/auth/emailVerification';

    try {
        const response: AxiosResponse<EmailVerificationResponse> = await axiosInstance.post<EmailVerificationResponse>(apiUrl, emailVerificationData);

        // Log the entire response data
        console.log('Email Verification Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error during email verification:', error.message);
        // Handle errors here
        throw error;
    }
}

export { emailVerification };
