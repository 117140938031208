import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

interface ResetPasswordData {
    token: string;
    password: string;
}

export interface ResetPasswordResponse {
    status_code: string;
    results: any;
}

async function resetPassword(resetPasswordData: ResetPasswordData): Promise<ResetPasswordResponse> {
    const apiUrl = 'api/v1/auth/resetPassword';

    try {
        const response: AxiosResponse<ResetPasswordResponse> = await axiosInstance.patch<ResetPasswordResponse>(apiUrl, resetPasswordData);

        // Log the entire response data
        console.log('Reset Password Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error during reset password request:', error.message);
        // Handle errors here
        throw error;
    }
}

export { resetPassword };
