import React, { useState } from "react";
import AuthIcon from "pages/AuthenticationInner/AuthIcon";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";

// Image
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";

// Import the adminSignup function from the API file
import { adminSignup, AdminSignupData, AdminSignupResponse } from "Common/data/adminAuth/AdminSignup";

const Register = () => {
    document.title = "Register | Scooty";

    const navigation = useNavigate(); // Use the useNavigate hook
    const [error, setError] = useState("");

    const validation = useFormic({
        // enableReinitialize: use this flag when initial values need to be changed
        enableReinitialize: true,

        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            password: "",
            role: "super_admin",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please Enter Your First Name"),
            lastName: Yup.string().required("Please Enter Your Last Name"),
            email: Yup.string().email().required("Please Enter Your Email"),
            phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            const { firstName, lastName, email, phoneNumber, password, role } = values;
            try {
                const response: AdminSignupResponse = await adminSignup({
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    password,
                    role,
                });
        
                // Check the status code in the response
                if (response.status_code === "0") {
                    navigation("/auth-two-steps-basic");
                }
            } catch (error: any) {
                setError(error.response.data.message);
                console.error('Error during form submission:', error.response.data.message);
            } finally {
                setSubmitting(false);
            }
        },        
    });

    React.useEffect(() => {
        const bodyElement = document.body;

        bodyElement.classList.add('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');

        return () => {
            bodyElement.classList.remove('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        }
    }, []);

    return (
        <React.Fragment>
            <div className="relative">

                <AuthIcon />

                <div className="mb-0 w-screen lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
                    <div className="!px-10 !py-12 card-body">
                        <Link to="/">
                            <img src={logoLight} alt="" className="hidden h-6 mx-auto dark:block" />
                            <img src={logoDark} alt="" className="block h-6 mx-auto dark:hidden" />
                        </Link>

                        <div className="mt-8 text-center">
                            <h4 className="mb-1 text-custom-500 dark:text-custom-500">Create your account</h4>
                        </div>

                        <form action="/" className="mt-10" id="registerForm"
                            onSubmit={(event: any) => {
                                event.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            <div className="mb-3">
                                <label htmlFor="firstName-field" className="inline-block mb-2 text-base font-medium">First Name</label>
                                <input
                                    type="text"
                                    id="firstName-field"
                                    name="firstName"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter first name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstName || ""} />
                                {validation.touched.firstName && validation.errors.firstName ? (
                                    <div id="firstName-error" className="mt-1 text-sm text-red-500">{validation.errors.firstName}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="lastName-field" className="inline-block mb-2 text-base font-medium">Last Name</label>
                                <input
                                    type="text"
                                    id="lastName-field"
                                    name="lastName"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter last name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastName || ""} />
                                {validation.touched.lastName && validation.errors.lastName ? (
                                    <div id="lastName-error" className="mt-1 text-sm text-red-500">{validation.errors.lastName}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email-field" className="inline-block mb-2 text-base font-medium">Email</label>
                                <input
                                    type="text"
                                    id="email-field"
                                    name="email"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""} />
                                {validation.touched.email && validation.errors.email ? (
                                    <div id="email-error" className="mt-1 text-sm text-red-500">{validation.errors.email}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phoneNumber-field" className="inline-block mb-2 text-base font-medium">Phone Number</label>
                                <input
                                    type="text"
                                    id="phoneNumber-field"
                                    name="phoneNumber"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter phone number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phoneNumber || ""} />
                                {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                                    <div id="phoneNumber-error" className="mt-1 text-sm text-red-500">{validation.errors.phoneNumber}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password-field" className="inline-block mb-2 text-base font-medium">Password</label>
                                <input
                                    type="password"
                                    id="password-field"
                                    name="password"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.password || ""} />
                                {validation.touched.password && validation.errors.password ? (
                                    <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.password}</div>
                                ) : null}
                            </div>

                            {error && (
                                <div className="px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-400/20">
                                    <strong>Error!</strong> {error}
                                </div>
                            )}

                            <div className="mt-10">
                                <button type="submit" disabled={validation.isSubmitting} className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    {validation.isSubmitting ? "Signing Up..." : "Sign Up"}
                                </button>
                            </div>

                            <div className="mt-10 text-center">
                                <p className="mb-0 text-slate-500 dark:text-zink-200">Already have an account ? <Link to="/login" className="font-semibold underline transition-all duration-150 ease-linear text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500">Login</Link> </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Register;

