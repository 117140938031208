interface AttendanceRecord {
    checkIn: string | null;
    checkOut: string | null;
}
interface BikemanData {
    [date: string]: AttendanceRecord;
}
interface PreprocessedDay {
    day: string;
    checkIn: string | null;
    checkOut: string | null;
}
interface PreprocessedBikeman {
    Bikeman: {
        firstName: string;
        lastName: string;
        bikeName: string;
    };
    days: PreprocessedDay[];
}

export const preprocessData = (data: any[]): PreprocessedBikeman[] => {
    const bikemen: { [key: string]: PreprocessedBikeman } = {};
    data.forEach((entry: any) => {
        const { Bikeman, date, status } = entry;
        const { id, firstName, lastName, bikeName } = Bikeman;
        const bikemanKey = `${id}_${firstName}_${lastName}`;
        if (!bikemen[bikemanKey]) {
            bikemen[bikemanKey] = {
                Bikeman: { firstName, lastName, bikeName },
                days: Array.from({ length: 30 }, (_, i) => ({ day: String(i + 1).padStart(2, '0'), checkIn: null, checkOut: null }))
            };
        }

        const dayIndex = parseInt(date.split('-')[2]) - 1;
        if (status === 'check-in') {
            bikemen[bikemanKey].days[dayIndex].checkIn = date;
        } else if (status === 'check-out') {
            bikemen[bikemanKey].days[dayIndex].checkOut = date;
        }
    });
    // Logging the processed data for debugging
    console.log("Processed Data:", bikemen);
    return Object.values(bikemen);
};       

export const calculateStatus = (checkIn: string | null, checkOut: string | null): string => { 
    if (!checkIn && !checkOut) { 
        return "-"; 
    } 
    if (checkIn && !checkOut) { 
        return "CheckedIn"; 
    } 
    if (checkIn && checkOut) { 
        const checkInDate = new Date(checkIn);
        const checkOutDate = new Date(checkOut);
        const diff = (checkOutDate.getTime() - checkInDate.getTime()) / (1000 * 60 * 60); 
        if (diff >= 8) { 
            return "FullDay"; 
        } else { 
            return "HalfDay"; 
        } 
    } 
    return "-";
};




