import React from 'react';

import { UpcomingInterviewData } from 'Common/data';

// SimpleBar
import SimpleBar from 'simplebar-react';

// Icons
import { MoreVertical, Calendar, Clock4 } from 'lucide-react';
import { Dropdown } from 'Common/Components/Dropdown';
import { Link } from 'react-router-dom';

const UpcomingInterview = () => {

    const Status = ({ item }: any) => {
        switch (item) {
            case "Confirm":
                return (<span className="px-2.5 py-0.5 text-xs inline-block text-center font-medium shrink-0 rounded border bg-white border-green-400 text-green-500 dark:bg-zink-700 dark:border-green-700">{item}</span>);
            case "Re-scheduled":
                return (<span className="px-2.5 py-0.5 text-xs text-center inline-block font-medium shrink-0 rounded border bg-white border-custom-400 text-custom-500 dark:bg-zink-700 dark:border-custom-700">{item}</span>);
            case "Scheduled":
                return (<span className="px-2.5 py-0.5 text-xs inline-block text-center font-medium shrink-0 rounded border bg-white border-sky-400 text-sky-500 dark:bg-zink-700 dark:border-sky-700">{item}</span>);
            case "Cancelled":
                return (<span className="px-2.5 py-0.5 text-xs inline-block text-center font-medium shrink-0 rounded border bg-white border-red-400 text-red-500 dark:bg-zink-700 dark:border-red-700">{item}</span>);
            default:
                return (<span className="px-2.5 py-0.5 text-xs inline-block text-center font-medium shrink-0 rounded border bg-white border-green-400 text-green-500 dark:bg-zink-700 dark:border-green-700">{item}</span>);
        }
    };

    return (
        <React.Fragment>
            
        </React.Fragment>
    );
};

export default UpcomingInterview;
