import React from 'react';
import { TotalProjectsChart } from './Charts';

// Icons
import { MoreVertical } from 'lucide-react';
import { Dropdown } from 'Common/Components/Dropdown';
import { Link } from 'react-router-dom';

const TotalProjects = () => {
    return (
        <React.Fragment>
            
        </React.Fragment>
    );
};

export default TotalProjects;
