import axios, { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper'; // Adjust the import path as per your project structure

export interface SubAdmin {
    id: number;
    profile_image_url: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailVerified: boolean;
    createdAt: string;
}

export interface SubAdminsResponse {
    status: string;
    results: number;
    data: {
        subAdmins: SubAdmin[];
    };
}

async function getAllSubAdmins(): Promise<SubAdmin[]> {
    const apiUrl = '/api/v1/subAdmins';

    try {
        const response: AxiosResponse<SubAdminsResponse> = await axiosInstance.get<SubAdminsResponse>(apiUrl);
        console.log('Sub Admins Response:', response.data);

        if (response.data.status === 'success') {
            return response.data.data.subAdmins.map(subAdmin => ({
                ...subAdmin,
                createdAt: new Date(subAdmin.createdAt).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                })
            }));
        } else {
            console.error('Failed to fetch sub-admins:', response.data.status);
            return [];
        }
    } catch (error: any) {
        console.error('Error fetching sub-admins:', error.message);
        throw error;
    }
}

export { getAllSubAdmins };
