import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';
interface CreateBikemanData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: string;
}
export interface CreateBikemanResponse {
    status: any;
    results: any;
    message: any;
}
async function createBikeman(createBikemanData: CreateBikemanData): Promise<CreateBikemanResponse> {
    const apiUrl = 'api/v1/admin/bikeman';
    try {
        const response: AxiosResponse<CreateBikemanResponse> = await axiosInstance.post<CreateBikemanResponse>(apiUrl, createBikemanData);
        // Log the entire response data
        console.log('Create Bikeman Response:', response.data);
        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error creating bikeman:', error.message);
        // Handle errors here
        throw error;
    }
}
export { createBikeman };