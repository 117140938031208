import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface GetAttendanceByDateResponse {
    status_code: number;
    results: any;
}

async function getAttendanceByDate(date: string): Promise<GetAttendanceByDateResponse> {
    const apiUrl = `api/v1/attendances/attendance/date?date=${date}`;

    try {
        const response: AxiosResponse<GetAttendanceByDateResponse> = await axiosInstance.get<GetAttendanceByDateResponse>(apiUrl);

        // Log the entire response data
        console.log(`Get Attendance By Date (${date}) Response:`, response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error(`Error fetching attendance with Date (${date}):`, error.message);
        // Handle errors here
        throw error;
    }
}

export { getAttendanceByDate };
