import { AxiosResponse } from "axios";
import axiosInstance from "baseURL/api_helper";

export interface Bike {
  id: number;
  name: string;
  plateNumber: string;
  // Add any other properties of the Bike interface here
}

export interface GetAllBikesResponse {
  status_code: number;
  results: any;
}

async function getAllBikes(): Promise<GetAllBikesResponse> {
  const apiUrl = "api/v1/admin/bikes";

  try {
    const response: AxiosResponse<GetAllBikesResponse> =
      await axiosInstance.get<GetAllBikesResponse>(apiUrl);

    console.log("Get All Bikes Response:", response.data);

    return response.data;
  } catch (error: any) {
    console.error("Error fetching all bikes:", error.message);
    throw error;
  }
}

export { getAllBikes };
