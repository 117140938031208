import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface GetBikeByIdResponse {
    status_code: number;
    results: any;
}

async function getBikeById(bikeId: number): Promise<GetBikeByIdResponse> {
    const apiUrl = `api/v1/admin/bike/${bikeId}`;

    try {
        const response: AxiosResponse<GetBikeByIdResponse> = await axiosInstance.get<GetBikeByIdResponse>(apiUrl);

        // Log the entire response data
        console.log('Get Bike By ID Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error(`Error fetching bike with ID ${bikeId}:`, error.message);
        // Handle errors here
        throw error;
    }
}

export { getBikeById };
