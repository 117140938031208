import React from "react";

interface DeleteModalProps {
    show: boolean;
    onClose: () => void;
    onDelete: () => void;
    deleteLoading: boolean;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ show, onClose, onDelete, deleteLoading }) => {
    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-4 max-w-sm w-full">
                <h3 className="text-lg font-semibold">Are you sure you want to delete?</h3>
                <div className="mt-4 flex justify-end gap-2">
                    <button
                        className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
                        onClick={onClose}
                        disabled={deleteLoading}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                        onClick={onDelete}
                        disabled={deleteLoading}
                    >
                        {deleteLoading ? "Deleting..." : "Yes, Delete"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;
