import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface AdminSignupData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    role: string;
}

export interface AdminSignupResponse {
    status_code: string;
    results: any;
    response: string;
    message: string;
    data: any;
}

async function adminSignup(adminSignupData: AdminSignupData): Promise<AdminSignupResponse> {
    const apiUrl = 'api/v1/auth/signup';

    try {
        const response: AxiosResponse<AdminSignupResponse> = await axiosInstance.post<AdminSignupResponse>(apiUrl, adminSignupData);

        // Log the entire response data
        console.log('Admin Signup Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error during admin signup:', error.message);
        // Handle errors here
        throw error;
    }
}

export { adminSignup };