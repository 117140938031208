import React, { useEffect } from 'react';
import Flatpickr from "react-flatpickr";
import supportImg from "assets/images/support.png";

import { UpcomingScheduledData } from 'Common/data';

const UpcomingScheduled = () => {

    useEffect(() => {
        document.getElementById('upcomingscheduledflatpickr')?.classList.add('hidden');
        return () => {
            document.getElementById('upcomingscheduledflatpickr')?.classList.remove('hidden');
        };
    }, []);

    return (
        <React.Fragment>
            
        </React.Fragment>
    );
};

export default UpcomingScheduled;
