import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface DeleteSubAdminResponse {
    status_code: number;
    message: string;
}

async function deleteSubAdmin(subAdminId: number): Promise<DeleteSubAdminResponse> {
    const apiUrl = `api/v1/subAdmins/${subAdminId}`;

    try {
        const response: AxiosResponse<DeleteSubAdminResponse> = await axiosInstance.delete<DeleteSubAdminResponse>(apiUrl);

        // Log the entire response data
        console.log('Delete Sub Admin Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error deleting sub admin:', error.message);
        // Handle errors here
        throw error;
    }
}

export { deleteSubAdmin };
