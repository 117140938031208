import React from 'react';
import { TotalEmployeeChart } from './Charts';
import CountUp from 'react-countup';
import ApplicationReceived from './ApplicationReceived';

  interface WidgetsProps { 
    totalStaffCount: number,
  }
	
const Widgets: React.FC<WidgetsProps> = ({totalStaffCount}) => {
    return (
        <React.Fragment>
            <div className="col-span-12 md:order-3 lg:col-span-6 2xl:col-span-3 card">
                <div className="card-body">
                    <div className="grid grid-cols-12">
                        <div className="col-span-8 md:col-span-9">
                            <p className="text-slate-500 dark:text-slate-200">Total Riders</p>
                            <h5 className="mt-3 mb-4">
                                <CountUp end={totalStaffCount} className="counter-value" />
                            </h5>
                        </div>
                        <div className="col-span-4 md:col-span-3">
                            <TotalEmployeeChart chartId="totalEmployee" dataChartColor='["bg-custom-500"]' series={[10]} />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-3">
                        <p className="text-slate-500 dark:text-slate-200 grow"><span className="font-medium text-green-500">15%</span> Increase</p>
                        <p className="text-slate-500 dark:text-slate-200">This Month</p>
                    </div>
                </div>
            </div>
            <ApplicationReceived />
        </React.Fragment>
    );
};

export default Widgets;