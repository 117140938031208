import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

interface ForgotPasswordData {
    email: string;
}

export interface ForgotPasswordResponse {
    status_code: string;
    results: any;
}

async function forgotPassword(forgotPasswordData: ForgotPasswordData): Promise<ForgotPasswordResponse> {
    const apiUrl = 'api/v1/auth/forgotPassword';

    try {
        const response: AxiosResponse<ForgotPasswordResponse> = await axiosInstance.post<ForgotPasswordResponse>(apiUrl, forgotPasswordData);

        // Log the entire response data
        console.log('Forgot Password Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error during forgot password request:', error.message);
        // Handle errors here
        throw error;
    }
}

export { forgotPassword };
