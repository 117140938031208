import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";
import AuthIcon from "../AuthIcon";
import { emailVerification } from "Common/data/adminAuth/EmailVerification";  // Adjust the import path as needed

const BasicTwoSteps = () => {
    document.title = "Two Steps | Scooty";

    const [digit1, setDigit1] = useState<string>('');
    const [digit2, setDigit2] = useState<string>('');
    const [digit3, setDigit3] = useState<string>('');
    const [digit4, setDigit4] = useState<string>('');
    const [digit5, setDigit5] = useState<string>('');
    const [digit6, setDigit6] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const digitRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

    const navigate = useNavigate();

    React.useEffect(() => {
        const bodyElement = document.body;
        bodyElement.classList.add('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');

        return () => {
            bodyElement.classList.remove('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        }
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const code = `${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`;
        const emailVerificationData = { token: code };

        try {
            const response = await emailVerification(emailVerificationData);
            console.log('Email Verification Successful:', response);
            navigate('/login'); // Navigate to the /login route on success
        } catch (error) {
            console.error('Email Verification Failed:', error);
            // Handle error (e.g., show an error message)
        } finally {
            setLoading(false);
        }
    };

    const handleDigitChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (value.match(/^[0-9]$/)) {
            switch (index) {
                case 0: setDigit1(value); break;
                case 1: setDigit2(value); break;
                case 2: setDigit3(value); break;
                case 3: setDigit4(value); break;
                case 4: setDigit5(value); break;
                case 5: setDigit6(value); break;
                default: break;
            }
            if (index < 5 && value) {
                digitRefs[index + 1].current?.focus();
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !e.currentTarget.value) {
            if (index > 0) {
                digitRefs[index - 1].current?.focus();
            }
            switch (index) {
                case 0: setDigit1(''); break;
                case 1: setDigit2(''); break;
                case 2: setDigit3(''); break;
                case 3: setDigit4(''); break;
                case 4: setDigit5(''); break;
                case 5: setDigit6(''); break;
                default: break;
            }
        }
    };

    return (
        <React.Fragment>
            <div className="relative">
                <AuthIcon />

                <div className="mb-0 w-screen lg:w-[500px] card shadow-lg border-none shadow-slate-100 dark:shadow-zink-500/20 relative">
                    <div className="!px-10 !py-12 card-body">
                        <Link to="/">
                            <img src={logoLight} alt="" className="hidden h-6 mx-auto dark:block" />
                            <img src={logoDark} alt="" className="block h-6 mx-auto dark:hidden" />
                        </Link>

                        <div className="mt-8 text-center">
                            <h4 className="mb-2 text-custom-500 dark:text-custom-500">Verify Email</h4>
                            <p className="mb-8 text-slate-500 dark:text-zink-200">Please enter the <b className="font-medium">6</b> digit code sent to your mail</p>
                        </div>

                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 gap-2 xl:grid-cols-6">
                                {[digit1, digit2, digit3, digit4, digit5, digit6].map((digit, index) => (
                                    <div key={index}>
                                        <label htmlFor={`digit${index + 1}-input`} className="hidden">Digit {index + 1}</label>
                                        <input
                                            type="text"
                                            className="text-lg text-center form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            required
                                            placeholder="0"
                                            maxLength={1}
                                            id={`digit${index + 1}-input`}
                                            value={digit}
                                            ref={digitRefs[index]}
                                            onChange={(e) => handleDigitChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="mt-10">
                                <button type="submit" className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" disabled={loading}>
                                    {loading ? 'Verifying Email...' : 'Confirm'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BasicTwoSteps;
