import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

interface AssignBikeToBikemanData {
    bikemanId: number;
    bikeId: number;
}

export interface AssignBikeToBikemanResponse {
    status: string;
    results: any;
}

async function assignBikeToBikeman(assignData: AssignBikeToBikemanData): Promise<AssignBikeToBikemanResponse> {
    const apiUrl = `api/v1/admin/assignBike?bikemanId=${assignData.bikemanId}&bikeId=${assignData.bikeId}`;

    try {
        const response: AxiosResponse<AssignBikeToBikemanResponse> = await axiosInstance.put<AssignBikeToBikemanResponse>(apiUrl);

        // Log the entire response data
        console.log('Assign Bike To Bikeman Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error assigning bike to bikeman:', error.message);
        // Handle errors here
        throw error;
    }
}

export { assignBikeToBikeman };
