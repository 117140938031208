import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface GetBikemanByIdResponse {
    status_code: number;
    results: any;
}

async function getBikemanById(bikemanId: number): Promise<GetBikemanByIdResponse> {
    const apiUrl = `api/v1/admin/bikeman/${bikemanId}`;

    try {
        const response: AxiosResponse<GetBikemanByIdResponse> = await axiosInstance.get<GetBikemanByIdResponse>(apiUrl);

        // Log the entire response data
        console.log('Get Bikeman By ID Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error(`Error fetching bikeman with ID ${bikemanId}:`, error.message);
        // Handle errors here
        throw error;
    }
}

export { getBikemanById };
