import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface AdminProfileResponse {
    status_code: number;
    results: any;
}

async function getAdminProfile(): Promise<AdminProfileResponse> {
    const apiUrl = 'api/v1/auth/me';

    try {
        const response: AxiosResponse<AdminProfileResponse> = await axiosInstance.get<AdminProfileResponse>(apiUrl);

        // Log the entire response data
        console.log('Admin Profile Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error fetching admin profile:', error.message);
        // Handle errors here
        throw error;
    }
}

export { getAdminProfile };
