export const calculateAttendanceStats = (attendanceData: any[], totalStaffCount: number) => {
    const presentIds = new Set();
    const halfDayIds = new Set();

    attendanceData.forEach(entry => {
        if (entry.status === 'check-in') {
            presentIds.add(entry.BikemanId);
        }
        if (entry.worktimeStatus === 'half-day') {
            halfDayIds.add(entry.BikemanId);
        }
    });

    const presentCount = presentIds.size;
    const halfDayCount = halfDayIds.size;
    const absentCount = totalStaffCount - presentCount;

    return {
        presentCount,
        halfDayCount,
        absentCount
    };
}