import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface UpdateAdminPasswordData {
    passwordCurrent: string;
    password: string;
    passwordConfirm: string;
}

export interface UpdateAdminPasswordResponse {
    status_code: number;
    results: any;
}

async function updateAdminPassword(updateAdminPasswordData: UpdateAdminPasswordData): Promise<UpdateAdminPasswordResponse> {
    const apiUrl = 'api/v1/auth/updateMyPassword';

    try {
        const response: AxiosResponse<UpdateAdminPasswordResponse> = await axiosInstance.patch<UpdateAdminPasswordResponse>(apiUrl, updateAdminPasswordData);

        // Log the entire response data
        console.log('Update Admin Password Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error during admin password update:', error.message);
        // Handle errors here
        throw error;
    }
}

export { updateAdminPassword };
