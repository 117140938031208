import { AxiosResponse } from 'axios';
import axiosInstance from 'baseURL/api_helper';

export interface GetAttendanceByMonthResponse {
    status_code: number;
    results: any;
}

async function getAttendanceByMonth(year: number, month: string): Promise<GetAttendanceByMonthResponse> {
    const apiUrl = `api/v1/attendances/attendance/month?year=${year}&month=${month}`;

    try {
        const response: AxiosResponse<GetAttendanceByMonthResponse> = await axiosInstance.get<GetAttendanceByMonthResponse>(apiUrl);

        // Log the entire response data
        console.log(`Get Attendance By Month (Year: ${year}, Month: ${month}) Response:`, response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error(`Error fetching attendance for Year (${year}) and Month (${month}):`, error.message);
        // Handle errors here
        throw error;
    }
}

export { getAttendanceByMonth };
