import { Award, CalendarDays, CircuitBoard, Codesandbox, FileText, LifeBuoy, LocateFixed, Mail, Map, MessageSquare, MonitorDot, PackagePlus, PictureInPicture2, PieChart, RadioTower, ScrollText, Share2, ShoppingBag, Table, Trophy, UserRound } from "lucide-react";

const menuData: any = [
    {
        id: 'dashboard',
        label: 'Dashboard',
        icon: <MonitorDot />,
        link: '/dashboard',
        parentId: "dashboard"
    },
    {
        id: "hr-management",
        label: 'Management',
        icon: <CalendarDays />,
        parentId: "hrmanagement",
        link: "/#",
        subItems: [
            {
                id: 'employeelist',
                label: 'List of Riders',
                link: '/apps-hr-employee',
                parentId: 'hrmanagement'
            },
            {
                id: 'mainattendance',
                label: 'Main Attendance',
                link: '/apps-hr-attendance-main',
                parentId: 'attendance'
            },
            {
                id: 'department',
                label: 'List of Bikes',
                link: '/apps-hr-department',
                parentId: 'hrmanagement'
            },
        ],
    },
    {
        id: 'setting',
        label: 'Settings',
        icon: <CircuitBoard />,
        link: '/pages-account-settings',
        parentId: 2
    },

];

export { menuData };