import axios from 'axios';

// Retrieve user data from local storage
const userDataString = localStorage.getItem('authUser') || '{}';
const userData = JSON.parse(userDataString);

// Extract the token from the user data
const authToken = userData.token;

const baseURL = 'https://api.myscooty.ng/';

// Create an Axios instance with the base URL and default headers
const axiosInstance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` })
    }
});

export default axiosInstance;
