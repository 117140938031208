import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from 'Common/BreadCrumb';
import { Link } from 'react-router-dom';
import TableContainer from 'Common/TableContainer';
import DeleteModal from 'Common/DeleteModal';
import Modal from 'Common/Components/Modal';
import { Plus, Search } from 'lucide-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllBikemen } from 'Common/data/bike/GetAllBikemen';
import { createBikeman } from 'Common/data/bike/CreateBikeman';
import { emailVerification } from 'Common/data/adminAuth/EmailVerification';
import { getBikeById } from 'Common/data/bike/GetBikeById'; 
import axios from 'axios';
import CheckInModal  from "./CheckInModal";
import CheckOutModal from "./CheckOutModal";
import { markAttendance } from 'Common/data/attendance/MarkAttendance';


const EmployeeList = () => {

    const [checkOutModalShow, setCheckOutModalShow] = useState<boolean>(false);

    const [data, setData] = useState<any[]>([]);
    const [eventData, setEventData] = useState<any>();
    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [checkInModalShow, setCheckInModalShow] = useState<boolean>(false);
    const [selectedBikemanId, setSelectedBikemanId] = useState<string>('');


    const navigate = useNavigate();

    const fetchBikemen = async () => {
        try {
            const response = await getAllBikemen();
            const transformedData = await Promise.all(response.results.map(async (item: any) => {
                let bike_assigned = "NIL"; // Default value if bikeId is null
                if (item.bikeId) {
                    const bikeResponse = await getBikeById(item.bikeId);
                    bike_assigned = bikeResponse.results.name; // Get the bike name from the response
                }
                return {
                    id: item.id,
                    employeeId: item.accountId,
                    name: `${item.firstName} ${item.lastName}`,
                    bike_assigned: bike_assigned, // Assign "NIL" if bikeId is null
                    phone: item.phoneNumber,
                    location: item.address,
                };
                
            }));
    
            console.log('Transformed Data:', transformedData);
            setData(transformedData);
    
           
        } catch (error) {
            console.error('Error fetching bikemen data:', error);
        }
    };

    useEffect(() => {
        fetchBikemen();
    }, []);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = useMemo(() => {
        return data.filter((item) => {
            // Filter data based on search query, handling null case
            return (
                (item.employeeId && item.employeeId.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item.bike_assigned && item.bike_assigned.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item.phone && item.phone.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item.location && item.location.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        });
    }, [data, searchQuery]);    

    const deleteToggle = () => setDeleteModal(!deleteModal);

    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    const handleDelete = () => {
        if (eventData) {
            // dispatch(onDeleteEmployee(eventData.id)); // Uncomment this line if you have an onDeleteEmployee action
            setDeleteModal(false);
        }
    };

    const handleUpdateDataClick = (ele: any) => {
        setEventData({ ...ele });
        setIsEdit(true);
        setShow(true);
    };

    const handleViewDataClick = (ele: any) => {
        // Extract the employee ID from the clicked row item
        const id = ele.id;
    
        // Navigate to the desired URL with the bikeman ID
        navigate(`/apps-hr-attendance/${id}`);
    };    

    const handleFormSubmit = async (values: any) => {
        try {
            // Extract only the required fields and rename them ilto match the expected properties
            const { firstname, lastname, phone, location, email } = values;
            const requestData = {
                firstName: firstname,
                lastName: lastname,
                phoneNumber: phone,
                email: email,
                address: location
            };
    
            console.log('Request Data:', requestData); // Log the data that will be sent to the API
    
            const response = await createBikeman(requestData); // Call createBikeman API function with the required data
            console.log('Bikeman created:', response);
            if (response.status === "success") {
                // Show success message and refresh bikemen list
                toast.success('You have successfully created a rider');
                fetchBikemen();
            } else if (response.status === "error") {
                // Show error message from the response
                toast.error('This phone number or email address already exists!');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Axios error handling
                const serverError = error.response?.data;
                toast.error('This phone number or email address already exists!');
            } else {
                // Generic error handling
                toast.error('This phone number or email address already exists!');
            }
        }
    };
    

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: eventData ? eventData.firstname : '',
            lastname: eventData ? eventData.lastname : '',
            email: eventData ? eventData.email : '',
            phone: eventData ? eventData.phone : '',
            location: eventData ? eventData.location : '',
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required('Please Enter First Name'),
            lastname: Yup.string().required('Please Enter Last Name'),
            email: Yup.string().notRequired(),
            phone: Yup.string().required('Please Enter Phone'),
            location: Yup.string().required('Please Enter Location'),
        }),
        onSubmit: handleFormSubmit, // Call handleFormSubmit when form is submitted
    });

    const handleCheckIn = (bikemanId: string) => {
        setSelectedBikemanId(bikemanId);
        setCheckInModalShow(true);
    };

    const handleCheckOut = (bikemanId: string) => {
        setSelectedBikemanId(bikemanId);
        setCheckOutModalShow(true);
    };
    
    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData('');
            setIsEdit(false);
        } else {
            setShow(true);
            setEventData('');
            validation.resetForm();
        }
    }, [show, validation]);

    const columns = useMemo(() => [
        {
            header: 'Rider ID',
            accessorKey: 'employeeId',
            cell: (cell: any) => (
                <Link to="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">{cell.getValue()}</Link>
            ),
        },
        {
            header: 'Name',
            accessorKey: 'name',
            cell: (cell: any) => (
                <Link to={`/apps-hr-attendance/${cell.row.original.id}`} className="flex items-center gap-3">
                    <h6 className="grow">{cell.getValue()}</h6>
                </Link>
            ),
        },
        {
            header: 'Bike Assigned',
            accessorKey: 'bike_assigned',
        },
        {
            header: 'Phone Number',
            accessorKey: 'phone',
        },
        {
            header: 'Location',
            accessorKey: 'location',
        },
        {
            header: 'Action',
            cell: (cell: any) => (
                <div className="flex gap-3">
                    <button className="flex items-center justify-center px-4 py-2 transition-all duration-200 ease-linear rounded-md bg-green-500 text-white hover:bg-green-600" onClick={() => handleCheckIn(cell.row.original.id)}>
                        Check In
                    </button>
                    <button className="flex items-center justify-center px-4 py-2 transition-all duration-200 ease-linear rounded-md bg-red-500 text-white hover:bg-red-600" onClick={() => handleCheckOut(cell.row.original.id)}>
                        Check Out
                    </button>
                </div>
            ),
        },
    ], []);   

    return (
        <React.Fragment>
            <BreadCrumb title="Riders List" pageTitle="Management" />
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
            <ToastContainer closeButton={false} limit={1} />
            <div className="card" id="employeeTable">
                <div className="card-body">
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center gap-3">
                            <h6 className="text-15 grow">Riders (<b className="total-Employs">{data.length}</b>)</h6>
                        </div>
                        <div className="flex items-center gap-3">
                            <input 
                                type="text" 
                                id="inputTitle" 
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800                     placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                                required 
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                value={searchQuery} // Ensure that the input field value is controlled by the searchQuery                   state
                                placeholder="Search..."
                            />
                                <Link to="#!" data-modal-target="addEmployeeModal" type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring                   active:ring-custom-100 dark:ring-custom-400/20 add-employee" onClick={toggle}>
                                <Plus className="inline-block size-4" /> <span className="align-middle">Add Rider</span>
                            </Link>
                        </div>
                    </div>
                    {data && data.length > 0 ? (
                        <TableContainer
                            isPagination={true}
                            columns={(columns || [])}
                            data={(filteredData || [])}
                            customPageSize={7}
                            divclassName="-mx-5 overflow-x-auto"
                            tableclassName="w-full whitespace-nowrap"
                            theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                            thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zink-500"
                            tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                            PaginationClassName="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row"
                        />
                    ) : (
                        <div className="noresult">
                            <div className="py-6 text-center">
                                {/* <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" /> */}
                                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                {/* <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched more than 299+ Riders We did not find any Rider for your search.</p> */}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Employee Modal */}
            <Modal show={show} onHide={toggle} modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
                <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                    <Modal.Title className="text-16">{!!isEdit ? "Edit Employee" : "Add Rider"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                    <form className="create-form" id="create-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <input type="hidden" value="" name="id" id="id" />
                        <input type="hidden" value="add" name="action" id="action" />
                        <input type="hidden" id="id-field" />
                        <div id="alert-error-msg" className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20"></div>
                        <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
                            
                            <div className="xl:col-span-12">
                                <label htmlFor="employeeInput" className="inline-block mb-2 text-base font-medium">First Name</label>
                                <input type="text" id="employeeInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Rider first name"
                                    name="firstname"
                                    onChange={validation.handleChange}
                                    value={validation.values.firstname || ""}
                                />
                                {validation.touched.firstname && validation.errors.firstname ? (
                                    <p className="text-red-400">{String(validation.errors.firstname)}</p>
                                ) : null}
                            </div>
                            <div className="xl:col-span-12">
                                <label htmlFor="employeeInput" className="inline-block mb-2 text-base font-medium">Last Name</label>
                                <input type="text" id="employeeInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Rider last name"
                                    name="lastname"
                                    onChange={validation.handleChange}
                                    value={validation.values.lastname || ""}
                                />
                                {validation.touched.lastname && validation.errors.lastname ? (
                                    <p className="text-red-400">{String(validation.errors.lastname)}</p>
                                ) : null}
                            </div>
                            <div className="xl:col-span-12">
                                <label htmlFor="emailInput" className="inline-block mb-2 text-base font-medium">Email</label>
                                <input type="text" id="emailInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="example@scooty.com"
                                    name="email"
                                    onChange={validation.handleChange}
                                    value={validation.values.email || ""}
                                />
                                {validation.touched.email && validation.errors.email ? (
                                    <p className="text-red-400">{String(validation.errors.email)}</p>
                                ) : null}
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="phoneNumberInput" className="inline-block mb-2 text-base font-medium">Phone Number</label>
                                <input type="text" id="phoneNumberInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter phone number"
                                    name="phone"
                                    onChange={validation.handleChange}
                                    value={validation.values.phone || ""}
                                />
                                {validation.touched.phone && validation.errors.phone ? (
                                    <p className="text-red-400">{String(validation.errors.phone)}</p>
                                ) : null}
                            </div>
                            <div className="xl:col-span-6">
                                <label htmlFor="locationInput" className="inline-block mb-2 text-base font-medium">Location</label>
                                <input type="text" id="locationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter location"
                                    name="location"
                                    onChange={validation.handleChange}
                                    value={validation.values.location || ""}
                                />
                                {validation.touched.location && validation.errors.location ? (
                                    <p className="text-red-400">{String(validation.errors.location)}</p>
                                ) : null}
                            </div>

                        
                        </div>
                        <div className="flex justify-end gap-2 mt-4">
                            <button 
                                type="button" 
                                id="close-modal" 
                                data-modal-close="addEmployeeModal" 
                                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10" 
                                onClick={toggle}
                            >
                                Cancel
                            </button>
                            <button 
                                type="button" 
                                id="addNew" 
                                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" 
                                onClick={() => {
                                    toggle(); // Close the modal
                                    if (!isEdit) {
                                        validation.submitForm(); // Submit the form if not in edit mode
                                    }
                                }}
                            >
                                {!!isEdit ? "Update" : "Add Rider"}
                            </button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>

            <CheckInModal
                show={checkInModalShow}
                onHide={() => setCheckInModalShow(false)}
                bikemanId={selectedBikemanId} // Pass available bikes
            />

            <CheckOutModal
                show={checkOutModalShow}
                onHide={() => setCheckOutModalShow(false)}
                bikemanId={selectedBikemanId} // Pass available bikes
            />

        </React.Fragment>
    );
};

export default EmployeeList;
