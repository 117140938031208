// attendanceUtils.ts

export const preprocessData = (data: any) => {
    const groupedData = data.reduce((acc: any, item: any) => {
        const { date, status, createdAt, Bikeman, Bike } = item;

        if (!Bike) {
            console.error('Bike is undefined:', item);
            return acc;
        }
        const { name, plateNumber } = Bike;

        if (!acc[date]) {
            const day = new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
            acc[date] = { date, checkIn: null, checkOut: null, day, bikeName: name, plateNumber };
        }
        const formattedTime = new Date(createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        if (status === 'check-in') {
            if (!acc[date].checkIn || new Date(createdAt) > new Date(acc[date].checkIn)) {
                acc[date].checkIn = formattedTime;
            }
        } else if (status === 'check-out') {
            if (!acc[date].checkOut || new Date(createdAt) > new Date(acc[date].checkOut)) {
                acc[date].checkOut = formattedTime;
            }
        }
        return acc;
    }, {});
    return Object.values(groupedData);
};

export const calculateStatus = (checkIn: string | null, checkOut: string | null) => {
    if (!checkIn && !checkOut) {
        return "Absent";
    }
    if (checkIn && checkOut) {
        const checkInDate = new Date(`1970-01-01T${checkIn}`);
        const checkOutDate = new Date(`1970-01-01T${checkOut}`);
        const diff = (checkOutDate.getTime() - checkInDate.getTime()) / (1000 * 60 * 60);
        if (diff >= 8) {
            return "Present";
        } else {
            return "Half Day";
        }
    }
    return "Present";
};
