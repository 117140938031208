import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { markAttendance } from 'Common/data/attendance/MarkAttendance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getAllBikes, Bike } from 'Common/data/bike/GetAllBikes'; 

// Define the app element for react-modal
Modal.setAppElement('#root');

interface CheckOutModalProps {
    show: boolean;
    onHide: () => void;
    bikemanId: string;
}

const CheckOutModal: React.FC<CheckOutModalProps> = ({ show, onHide, bikemanId }) => {
    const [bikes, setBikes] = useState<Bike[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchBikes = async () => {
            try {
                const response = await getAllBikes();
                setBikes(response.results);
            } catch (error) {
                console.error('Error fetching bikes:', error);
            }
        };

        fetchBikes();
    }, []);

    const validationSchema = Yup.object({
        bike: Yup.string().required('Please select a bike'),
        comment: Yup.string().required('Comment is required'),
    });

    const handleCheckOut = async (values: any) => {
        try {
            const { bike, comment } = values;
            const status = 'check-out';
            const worktimeStatus = 'full-day';

            setLoading(true);
            const response = await markAttendance({ bikemanId, bikeId: bike, comment, status, worktimeStatus });
            console.log('Attendance marked successfully:', response);
            toast.success('Check-out successful');
            onHide();
        } catch (error) {
            console.error('Error marking attendance:', error);
            toast.error('Failed to check-Out');
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            bike: '',
            comment: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleCheckOut,
    });

    return (
        <Modal
            isOpen={show}
            onRequestClose={onHide}
            className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50"
            overlayClassName="Overlay"
        >
            <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium">Check Out</h2>
                    <button onClick={onHide} className="text-gray-500 hover:text-red-500">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="bike" className="block text-sm font-medium text-gray-700">Select Bike</label>
                        <Select
                            id="bike"
                            name="bike"
                            options={bikes.map(bike => ({ value: bike.id, label: bike.name }))}
                            onChange={(selectedOption: any) => formik.setFieldValue('bike', selectedOption.value)}
                            defaultValue={formik.values.bike}
                            placeholder="Select Bike"
                        />
                        {formik.touched.bike && formik.errors.bike ? (
                            <p className="text-red-400">{formik.errors.bike}</p>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Comment</label>
                        <textarea
                            id="comment"
                            name="comment"
                            className="form-textarea mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                            rows={3}
                            onChange={formik.handleChange}
                            value={formik.values.comment}
                            placeholder="Enter comment"
                        />
                        {formik.touched.comment && formik.errors.comment ? (
                            <p className="text-red-400">{formik.errors.comment}</p>
                        ) : null}
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onHide}
                            className="button bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="button bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                            disabled={loading}
                        >
                            {loading ? 'Checking Out...' : 'Check Out'}
                        </button>
                    </div>
                </form>
            </div>
            <ToastContainer position="bottom-right" />
        </Modal>
    );
};

export default CheckOutModal;
